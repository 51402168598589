'use client';

import { MessageEventPayload, RoomOptionsDefaults } from '@ably/chat';
import { SnackbarCloseReason, Snackbar, Button, Stack, Typography, Avatar } from '@mui/material';
import { usePathname } from 'next/navigation';
import React, { useEffect, useState } from 'react';
import { useGetMatches } from 'src/api/hooks/connect';
import { useAuthContext } from 'src/auth/hooks';
import { chatService } from 'src/services/ablyClient'; // Assuming you have an auth context for user state

type Props = {
  children: React.ReactNode;
};

export default function Layout({ children }: Props) {
  const { user } = useAuthContext(); // Access the user state
  const [rooms, setRooms] = useState<any[]>([]);
  const [messages, setMessage] = useState<MessageEventPayload | null>(null);
  const pathname = usePathname();
  const { data: matchesList } = useGetMatches({ skip: 0 }, !!user);

  // Check if the current path does not start with `/messages`
  const isNotMessagesPath = !pathname.startsWith('/messages');
  const isNotMyMessage = messages && Number(messages?.message.clientId) !== user?.id;

  useEffect(() => {
    const setupRooms = async () => {
      if (!user || !matchesList) {
        return;
      }

      console.log('!user || !chatService.getChatClient()', !user, !chatService.getChatClient());

      const initializedRooms = await Promise.all(
        matchesList?.results
          ?.filter((match) => match?.chatChannelId)
          .map(async (mch) => {
            const room = chatService
              .getChatClient()
              .rooms.get(mch.chatChannelId, RoomOptionsDefaults);

            if (room) {
              await room.attach(); // Attach each room

              const { unsubscribe } = room.messages.subscribe((message) => {
                console.log(`Message from room ${mch.chatChannelId}:`, message);
                setMessage(message);
              });

              console.log(`Room ${mch.chatChannelId} attached`);
              return {
                unsubscribe,
                detach: () => room.detach(),
              };
            }

            return undefined;
          })
      );

      setRooms(initializedRooms.filter(Boolean));
    };

    setupRooms();

    return () => {
      rooms?.forEach(({ unsubscribe, detach }) => {
        if (unsubscribe) unsubscribe();
        if (detach) detach();
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, matchesList?.results]); // Rerun effect if user changes

  const handleClose = (event: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }

    setMessage(null);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={
          !!messages &&
          !!isNotMyMessage &&
          messages.message.text !== 'ZIZIGULU' &&
          isNotMessagesPath
        }
        onClose={handleClose}
        autoHideDuration={5000}
        sx={{
          '& .MuiSnackbarContent-root': {
            backgroundColor: '#000',
          },
        }}
        message={
          <Stack
            direction="row"
            alignItems="center"
            gap={1}
            sx={{
              '&.MuiSnackbarContent-root': {
                backgroundColor: 'red',
              },
            }}
          >
            <Avatar
              alt=" messages?.message?.metadata?.userPicture"
              src={(messages?.message?.metadata?.userPicture as string) ?? ''}
              sx={{ width: 48, height: 48 }}
            />
            <Typography color="#fff"> {messages?.message?.text}</Typography>
          </Stack>
        }
        action={
          <Button href={`/messages/${messages?.message.roomId}`} color="secondary" size="small">
            Reply
          </Button>
        }
      />

      {children}
    </>
  );
}
